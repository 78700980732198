.about-page-first{
    height: 70vh;
    margin-bottom: 5rem;
}

.about-page-basic{
    color: whitesmoke;
    width: 90%;
    padding: 10rem 5rem 5rem;
    letter-spacing: 2px;
}

.about-page-basic-text{
    padding-top: 2rem;
    width: 90%;
}

.about-page-heading1{
    letter-spacing: 5px;
}

.shortcuts {
    display: flex;
    flex-direction: row;
}

.cta {
    padding: 2rem 2rem 2rem 0;
    border: none;
    background: none;
}

.cta span {
    color: whitesmoke;
    padding-bottom: 7px;
    letter-spacing: 4px;
    font-size: 14px;
    padding-right: 15px;
    text-transform: uppercase;
    font-weight: 600;
}

.cta svg {
    transform: translateX(-8px);
    transition: all 0.3s ease;
}

.cta:hover svg {
    transform: translateX(0);
}

.cta:active svg {
    transform: scale(0.9);
}

.hover-underline-animation {
    position: relative;
    padding-bottom: 20px;
}

.hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: whitesmoke;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: whitesmoke;
}


.cta span {
    color: whitesmoke;
    padding-bottom: 7px;
    letter-spacing: 4px;
    font-size: 14px;
    padding-right: 15px;
    text-transform: uppercase;
    font-weight: 600;
}

.cta svg {
    transform: translateX(-8px);
    transition: all 0.3s ease;
}

.cta:hover svg {
    transform: translateX(0);
}

.cta:active svg {
    transform: scale(0.9);
}


.loader {
    padding-bottom: 10rem;
    scale: 3;
    height: 60px;
    width: 50px;
    float: right;
    display: none;
}

.loader-text{
    margin: auto;
    font-weight: 600;
    font-size: 3px;
    color: black;
    padding-top: 5px;
}

.loader-text-side{
    margin: auto;
    font-weight: 600;
    font-size: 3px;
    color: black;
    padding-top: 1px;
}

.side-top {
    position: absolute;
    background-color: #5fa8f5;
    width: 20px;
    height: 20px;
    rotate: 45deg;
    transform: skew(-20deg, -20deg);
}

@keyframes from-left {
    0% {
        z-index: 20;
        opacity: 0;
        translate: -20px -6px;
    }

    20% {
        z-index: 10;
        opacity: 1;
        translate: 0 0;
    }

    40% {
        z-index: 9;
        translate: 0 4px;
    }

    60% {
        z-index: 8;
        translate: 0 8px;
    }

    80% {
        z-index: 7;
        opacity: 1;
        translate: 0 12px;
    }

    100% {
        z-index: 5;
        translate: 0 30px;
        opacity: 0;
    }
}

@keyframes from-right {
    0% {
        z-index: 20;
        opacity: 0;
        translate: 20px -6px;
    }

    20% {
        z-index: 10;
        opacity: 1;
        translate: 0 0;
    }

    40% {
        z-index: 9;
        translate: 0 4px;
    }

    60% {
        z-index: 8;
        translate: 0 8px;
    }

    80% {
        z-index: 7;
        opacity: 1;
        translate: 0 12px;
    }

    100% {
        z-index: 5;
        translate: 0 30px;
        opacity: 0;
    }
}

.hamburger-about{
    opacity: 0;
}

#overlayAbout {
    display: none;
}

.about-page-basic{
    animation-name: enter-from-left;
    animation-duration: 2s;
}

@keyframes enter-from-left {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes enter-from-bottom {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.about-part2{
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    animation-name: enter-from-bottom;
    animation-duration: 2s;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 8rem;
}

.about-part2-mobile{
    display: none;
}

.about-section1{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10rem;
}


.about-section1-row1{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.about-section1-heading{
    text-align: center;
}

.about-image-cont{
    margin: 10rem;
}

.about-section1-row2-image{
    width: 60%;
    height: 300px;
    border-radius: 20px;
    filter: brightness(60%);
    box-shadow: 0 15px 25px rgba(0,0,0,.6);

}

.about-section1-text{
    padding: 0 2rem ;
    text-align: center;
    font-size: 100%;
}

.hor-line-about{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.hor-line-about > hr{
    width: 70%;
    margin: 1.5rem 1.5rem 5rem;
    background-color: lightskyblue;
}

.bulleted-list {
    list-style-type: disc; /* Set desired list style type */
    padding-left: 1rem; /* Set desired left padding for the list items */
    font-size: 110%;
}

.bulleted-list > hr {
    width: 95%;
    margin: 15px 0;
    padding: 0;
    height: 2px;
    background-color: #03f40f;
}

.about-section3-image{
    width: 550px;
    height: auto;
    border-radius: 20px;
    filter: brightness(60%);
    margin-left: 3rem;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
}

@media (max-width: 850px) {

    .navbar-item1{
        display: none;
    }

    .shortcuts{
        display: none;
    }

    .hamburger-about {
        cursor: pointer;
        top: 0;
        right: 0;
        position: fixed;
        padding-right: 2rem;
        padding-top: 1rem;
        opacity: 1;
    }

    .navbar-hamburger-scroll{
        position: fixed;
        z-index: 1;
        transition: background-color 1.5s ease-in-out;
        transform: scale(0.9);
    }

    .hamburger-about input {
        display: none;
    }

    .hamburger-about svg {
        /* The size of the SVG defines the overall size */
        height: 3em;
        /* Define the transition for transforming the SVG */
        transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .hamburger-about input:checked + svg {
        transform: rotate(-180deg);
    }

    #overlayAbout{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(5px);
        z-index: 999;
    }

    .text-container-about {
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 500;
        text-align: center;
        background-color: transparent;
        padding: 20px;
        border-radius: 5px;
        z-index: 999;
        animation-name: enter-from-bottom;
        animation-duration: 0.5s;
    }

    .text-container-about p {
        margin-bottom: 2rem;
        letter-spacing: 3px;
    }

    .about-page-basic{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .loader {
        padding-top: 6rem;
        scale: 3;
        height: 60px;
        width: 50px;
        display: flex;
    }

    .loader-text{
        margin: auto;
        font-weight: 600;
        font-size: 2.5px;
        color: black;
        padding-top: 5px;
    }

    .loader-text-side{
        margin: auto;
        font-weight: 600;
        font-size: 2.5px;
        color: black;
        padding-top: 1px;
    }

    .about-page-first{
        height: 40vh;
    }

    .about-page-basic{
        color: whitesmoke;
        width: 100%;
        padding: 6rem 1rem 5rem;
        letter-spacing: 2px;
    }

    .about-page-basic-text{
        padding-top: 2rem;
        width: 100%;
        text-align: center;
    }

    .about-page-heading1{
        letter-spacing: 5px;
        text-align: center;
    }


    .about-part2{
        display: none;
    }


    .about-part2-mobile{
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
        padding-bottom: 5rem;
        animation-name: enter-from-bottom;
        animation-duration: 1s;
    }

    .card-div2, .front-div2, .back-div2{
        height: 22rem;
    }


}
