.contact-page-main{
    height: 100vh;
}

.dropdown-content-contact a:hover {
    background-color: rgba(0, 0, 0, 0.6);
    transform: scale(1);
    color: white;
}

.dropdown-content-contact {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4); /* set background to transparent */
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 100%; /* position dropdown menu under navbar item */
    left: 50%; /* center dropdown menu */
    transform: translateX(-50%); /* center dropdown menu */
    margin-top: -30px;
    padding-top: 5px;
}

.contact-info-mail p{
    margin: 0 10px 10px;
}

.contact-info-media{
    margin: 0 20px;
}

.contact-mail1{
    display: flex;
    flex-direction: row;
}

.contact-page-formRow{
    padding-top: 6rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 5rem;
}


.contactPage-button{
    color: black;
    font-weight: 500;
}

.contact-page-info{
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin: 0 auto;
    max-width: 500px;
    background-color: rgba(0, 0, 0, 0.70);
    border-radius: 15px;
    padding-top: 1rem;
    width: 40%;
    order: 1;
}

.contact-image{
    width: 40px;
    height: 40px;
    margin-bottom: 3px;
}

.contact-page-form{
    width: 60%;
    order: 2;

}

.contact-signup {
    color: #000;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: block;
    font-weight: bold;
    font-size: x-large;
    margin-top: 1.5em;
}

.contact-card {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 350px;
    height: 30rem;
    width: 90%;
    flex-direction: column;
    gap: 35px;
    border-radius: 15px;
    background: #e3e3e3;
}

.inputBox,
.inputBox1 {
    position: relative;
    width: 80%;
}

.inputBox input,
.inputBox1 input {
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    color: #000;
    font-size: 1em;
    background: transparent;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    transition: 0.1s;
    border-bottom-left-radius: 8px;
}

.inputBox span,
.inputBox1 span {
    margin-top: 5px;
    position: absolute;
    left: 0;
    transform: translateY(-4px);
    margin-left: 10px;
    padding: 10px;
    pointer-events: none;
    font-size: 12px;
    color: #000;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 3px;
    border-radius: 8px;
}

.inputBox input:valid~span,
.inputBox input:focus~span {
    transform: translateX(113px) translateY(-15px);
    font-size: 0.8em;
    padding: 5px 10px;
    background: #000;
    letter-spacing: 0.2em;
    color: #fff;
    border: 2px;
}

.inputBox1 input:valid~span,
.inputBox1 input:focus~span {
    transform: translateX(156px) translateY(-15px);
    font-size: 0.8em;
    padding: 5px 10px;
    background: #000;
    letter-spacing: 0.2em;
    color: #fff;
    border: 2px;
}

.inputBox input:valid,
.inputBox input:focus,
.inputBox1 input:valid,
.inputBox1 input:focus {
    border: 2px solid #000;
    border-radius: 8px;
}

.enter {
    height: 45px;
    width: 100px;
    border-radius: 5px;
    border: 2px solid #000;
    cursor: pointer;
    background-color: transparent;
    transition: 0.5s;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 2px;
    margin-bottom: 3em;
}

.enter:hover {
    background-color: rgb(0, 0, 0);
    color: white;
}

.locationBox{
    width: 100%;
    height: 70vh;
}


.contact-page-info{
    animation-name: enter-from-left;
    animation-duration: 2s;
}

.contact-page-form{
    animation-name: enter-from-right;
    animation-duration: 2s;
}

.locationBox{
    animation-name: enter-from-bottom;
    animation-duration: 2s;
}

@keyframes enter-from-bottom {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes enter-from-left {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes enter-from-right {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}


@media (max-width: 850px) {

    .contact-page-main{
        height: 180vh;
    }

    .contact-mail1{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-page-formRow{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 6rem;
        padding-top: 0;
        padding-bottom: 0;
    }

    .contact-page-info{
        color: whitesmoke;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        width: 95%;
        background-color: rgba(0, 0, 0, 0.70);
        border-radius: 15px;
        padding-top: 1rem;
        order: 2;
        margin-bottom: 4rem;
    }

    .contact-page-form{
        margin-bottom: 4rem;
        width: 100%;
        order: 1;

    }

    .contact-signup {
        color: #000;
        text-transform: uppercase;
        letter-spacing: 2px;
        display: block;
        font-weight: bold;
        font-size: x-large;
        margin-top: 1.5em;
    }

    .contact-card {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 350px;
        width: 100%;
        flex-direction: column;
        gap: 35px;
        border-radius: 15px;
        background: #e3e3e3;
    }

    .locationBox{
        width: 100%;
        height: 50vh;
    }
}