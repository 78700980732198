.cardDivisions{
    display: flex;
    flex-direction: row;
    border-radius: 1rem;
    width: 100%;
    height: 320px;
    position: relative;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);

}

.cardImgDivisions{
    height: 100%;
    width: 100%;
    filter: brightness(25%);
    object-fit: cover;
}

.cardTitleDivisions{
    position: absolute;
    z-index: 1;
    width: 40%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    letter-spacing: 7px;
    text-align: center;
}



.cardTextDivisions{
    width: 60%;
    padding: 3rem 3rem 3rem 0;;
    position: absolute;
    z-index: 1;
    letter-spacing: 2px;
    font-weight: 500;
    margin: 0;
    font-size: 110%;
    color: white;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}



/*******DivisionCard1.tsx*********/

.cardDivisions1{
    display: flex;
    flex-direction: row;
    border-radius: 1rem;
    width: 100%;
    height: 320px;
    position: relative;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);

}

.cardImgDivisions1{
    height: 100%;
    width: 100%;
    filter: brightness(25%);
    object-fit: cover;
}

.cardTitleDivisions1{
    position: absolute;
    z-index: 1;
    width: 40%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    letter-spacing: 7px;
    text-align: center;
}



.cardTextDivisions1{
    width: 60%;
    padding: 3rem 3rem 3rem 3rem;
    position: absolute;
    z-index: 1;
    letter-spacing: 2px;
    font-weight: 500;
    margin: 0;
    font-size: 110%;
    color: white;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}




